/* Global Editor Styling */

ul,
ol {
  padding: 0 0 0 40px;
}

p {
  font-size: 12pt;
  color: black;
  margin-bottom: 0;
}

h1 {
  font-size: 35pt;
  color: black;
  font-weight: 500;
}

h2 {
  font-size: 30pt;
  color: black;
  font-weight: 500;
}

h3 {
  font-size: 25pt;
  color: black;
  font-weight: 500;
}

h4 {
  font-size: 20pt;
  color: black;
  font-weight: 500;
}

h5 {
  font-size: 15pt;
  color: black;
  font-weight: 500;
}

h6 {
  font-size: 12pt;
  color: black;
  font-weight: 500;
}

#headers {
  margin-left: 10px;
}

.font-16 {
  font-size: 16px !important;
  margin-bottom: 1px;
}

/* End of Global Styling */

.cms-button {
  padding: 5px 0;
  width: 130px;
  color: white;
  font-weight: 500;
  border: 0;
  background-color: #fa3b34;
  position: absolute;
  right: 26px;
  margin-top: -80px;
  z-index: 10;
  letter-spacing: 1px;
}

.down-button {
  padding: 5px 0;
  width: 180px;
  color: white;
  font-weight: 500;
  border: 0;
  background-color: rgb(22, 166, 182);
  position: absolute;
  left: 226px;
  margin-top: -80px;
  z-index: 10;
  letter-spacing: 1px;
}

.up-button {
  padding: 5px 0;
  width: 180px;
  color: white;
  font-weight: 500;
  border: 0;
  background-color: rgb(22, 166, 182);
  position: absolute;
  left: 26px;
  margin-top: -80px;
  z-index: 10;
  letter-spacing: 1px;
}

.cms-img-col {
  padding: 15px;
  color: rgb(22, 166, 182);
  border: 1px solid rgb(250, 59, 52);
  background-size: 100% 100%;
}

.cms-back-img-col {
  padding: 15px;
  color: rgb(22, 166, 182);
  border: 1px solid rgb(250, 59, 52);
  background-size: 100% 100%;
}

.cms-col {
  padding: 20px;
  min-height: 50px;
  border: 1px dotted rgb(22, 166, 182);
  background-color: #fff;
}

.col-text {
  padding: 85px 150px 40px 85px;
}

div {
  font-size: 1rem;
  letter-spacing: 0px;
}

.d-flex {
  display: flex;
}

.intro-section {
  padding: 110px 0;
}

.intro-col-text {
  padding: 50px 150px 50px 150px;
}

section {
  padding: 90px 20px 20px;
  min-height: 50px;
  border: 1px solid black;
  background-color: #fff;
  margin: 20px 0 0;
}

.cms-black-t {
  color: black !important;
}
